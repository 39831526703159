<template>
  <div class="home-menu section">
    <h3>Меню послуг</h3>

    <Loader v-if="areas.length == 0" />

    <div class="home-areas" v-else>
      <ul>
        <li v-for="(area, index) in areas" :key="index">
          <router-link class="home-area" :to="'/category?area=' + area">
            <div class="head">
              <h5>
                {{ area }}
              </h5>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 288.824 288.824"
                xml:space="preserve"
              >
                <path
                  d="M3.501,285.323c4.668,4.668,12.235,4.668,16.891,0L264.763,40.951v91.382
		c0,6.641,5.39,12.03,12.03,12.03c6.641,0,12.03-5.39,12.03-12.03V12.03c0-3.188-1.239-6.268-3.501-8.529S279.981,0,276.793,0
		H156.49c-6.641,0-12.03,5.39-12.03,12.03s5.39,12.03,12.03,12.03h91.382L3.501,268.432C-1.167,273.1-1.167,280.667,3.501,285.323z"
                />
              </svg>
            </div>
            <p>
              {{ getInfo(area) }}
            </p>
          </router-link>
        </li>

        <li class="areas-help">
          <h5>Не знайшли послугу?</h5>
          <p>
            Сервіс містить інформацію про всі наявні послуги ЦНАП Бердичева. Не знайшли послугу, що шукали? Напишіть нам
            і ми спробуємо допомогти.
          </p>
          <router-link class="button" to="/contacts">Контакти</router-link>
        </li>
      </ul>

      <div class="areas-help areas-help--self">
        <h5>Не знайшли послугу?</h5>
        <p>
          Сервіс містить інформацію про всі наявні послуги ЦНАП Бердичева. Не знайшли послугу, що шукали? Напишіть нам і
          ми спробуємо допомогти.
        </p>
        <router-link class="button" to="/contacts">Контакти</router-link>
      </div>
    </div>
  </div>
</template>

<script>
  import { useStore } from "vuex";
  import { computed } from "vue";

  import description from "../../store/description";

  import Loader from "../global/Loader.vue";

  export default {
    components: { Loader },
    name: "Menu",

    setup() {
      const store = useStore();
      const areas = computed(() => store.state.areas);

      const info = description.main;

      const getInfo = (key) => {
        return info.find((e) => e.title === key).discription;
      };

      return { areas, getInfo };
    },
  };
</script>

<style lang="scss">
  .home-menu {
    .home-areas {
      margin-top: 50px;

      ul {
        display: grid;
        grid-template-columns: repeat(3, minmax(240px, 1fr));
        grid-column-gap: 32px;
        grid-row-gap: 64px;

        @media screen and (max-width: 968px) {
          grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (max-width: 585px) {
          grid-template-columns: 1fr;
        }

        li {
          a.home-area {
            position: relative;
            display: grid;
            grid-gap: 10px;
            padding: 2vw;

            .head {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;

              svg {
                flex-shrink: 0;
                width: 16px;
                height: 16px;
                margin-top: 10px;
                fill: #fff;
              }
            }

            &::before {
              position: absolute;
              content: "";
              width: 100%;
              height: 2px;
              top: 0;
              left: 0;
              background: $black;
              transition: 0.2s ease;
              z-index: -1;
            }

            &:hover {
              p,
              h5 {
                color: #fff;
              }

              &::before {
                height: 100%;
              }
            }

            p,
            h5 {
              margin: 0;
            }

            h5 {
              font-weight: 500;
            }

            p {
              font-size: 0.8em;
            }
          }

          &.areas-help {
            grid-area: 1 / 3 / 3 / 4;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            background: $acs-trans;
            padding: 5vw;

            @media screen and (max-width: 968px) {
              display: none;
            }
          }
        }
      }

      .areas-help {
        &--self {
          display: none;
          flex-direction: column;
          justify-content: center;
          align-items: start;
          background: $acs-trans;
          margin: 5vw;
          padding: 5vw;

          @media screen and (max-width: 968px) {
            display: flex;
          }
        }
      }
    }
  }
</style>
