<template>
  <Header />
  <router-view />
  <Footer />
  <Modal />
</template>

<script>
  import { useStore } from "vuex";
  import { onBeforeMount, onMounted } from "vue";
  import Header from "./components/global/Header.vue";
  import Footer from "./components/global/Footer.vue";
  import Modal from "./components/global/Modal.vue";

  export default {
    name: "App",
    components: { Header, Footer, Modal },

    setup() {
      const store = useStore();

      onBeforeMount(() => {
        store.dispatch("fetchSrvices");
      });

      onMounted(() => {
        //setTimeout(() => {
          //if (localStorage.getItem("covid") == null) {
            //store.commit("setModal", "covid");
            //localStorage.setItem("covid", "true");
         // }
        //}, 300);
     });
    },
  };
</script>

<style lang="scss">
  #app {
    position: relative;
    font-family: "Rubik", sans-serif;
    font-weight: 300, 400, 500, 600, 800;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: block;
    width: 100%;
    max-width: 1680px;
    min-height: 100vh;
    top: 0;
    left: 0;
    margin: 0 auto;
    box-sizing: border-box;
  }
</style>
