<template>
  <div class="events section">
    <div class="events-header">
      <h3>Життєві події та ситуації</h3>
      <p>
        Що варто знати, куди звертатись та як діяти у випадку приємних та не дуже, очікуваних або несподіваних подій та
        ситуацій
      </p>
    </div>

    <Loader v-if="events.length == 0" />

    <ul class="events-content" v-else>
      <li v-for="(event, index) in events" :key="index">
        <router-link :to="'category?event=' + event">
          {{ event }}
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
  import { useStore } from "vuex";
  import { computed } from "vue";

  import Loader from "../global/Loader.vue";

  export default {
    components: { Loader },
    name: "Events",

    setup() {
      const store = useStore();
      const events = computed(() => store.state.events);

      return { events };
    },
  };
</script>
<style lang="scss">
  .events {
    background: $acs-trans;
    .events-header {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));

      p {
        margin-top: 32px;
      }
    }

    ul.events-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin-top: 32px;
      grid-gap: 0.5em;

      @media screen and (max-width: 586px) {
        grid-template-columns: 1fr;
      }

      li {
        margin: 12px 0;
      }
    }
  }
</style>
