import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    services: [],
    areas: [],
    events: [],

    modal: null,
  },
  mutations: {
    setServices(state, payload) {
      state.services = payload;
    },
    setAreas(state, payload) {
      state.areas = payload;
    },
    setEvents(state, payload) {
      state.events = payload;
    },

    setModal(state, payload) {
      state.modal = payload;
    },
  },
  actions: {
    async fetchSrvices({ commit }) {
      try {
        let get = await axios.get(
            "https://sheets.googleapis.com/v4/spreadsheets/14QM9kVjbmMOMHv9N6WEs3r0TH8X2d4f3wSmMUCQORa4/values/Services?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas"
          ),
          header = get.data.values[0],
          rows = get.data.values.slice(1),
          formated = [];

        for (const row of rows) {
          const formattedRow = {};

          header.forEach((e, i) => {
            formattedRow[e.toLowerCase()] = row[i];
          });

          formated.push(formattedRow);
        }

        commit("setServices", formated);

        commit("setAreas", [...new Set(formated.map((e) => e.thematic_area))]);

        commit("setEvents", [
          ...new Set(
            formated
              .map((e) => e.events)
              .join(", ")
              .split(/, (?=[А-ЯІ])/g)
          ),
        ]);
      } catch (e) {
        commit("setModal", "error");
        console.log(e);
      }
    },
  },
  modules: {},
});
