<template>
  <div class="home">
    <Hero />
    <Menu />
    <Events />
  </div>
</template>

<script>
  import Events from "../components/home/Events.vue";
  import Hero from "../components/home/Hero.vue";
  import Menu from "../components/home/Menu.vue";

  export default {
    name: "Home",
    components: {
      Hero,
      Menu,
      Events,
    },
  };
</script>
