export default [
  {
    name: "help",
    title: "Пошук послуг",
    message: [
      "Введіть у рядку пошуку ключове слово чи словосполучення із назви послуги, яку ви шукаєте. Наприклад, «місце проживання» чи «прописка»",
      " У результатах пошуку відшукайте необхідну вам послугу або ж знайдіть її у життєвій ситуації, яку вам пропонує результат пошуку",
    ],
  },
  {
    name: "covid",
    title: "COVID-19",
    message: [
      "Звертаємо Вашу увагу, що на період карантину ЦНАП приймає відвідувачів за попереднім записом.",
      "Попередній запис здійснюється за номерами телефонів: (04143) 2-13-81, (098) 959-28-47.",
    ],
  },
  {
    name: "error",
    title: "Помилка",
    message: ["Виникла помилка при підключенні до реєстру послуг.", "Спробуйте пізніше"],
  },
];
