import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/faq",
    name: "Faq",
    meta: { title: "Притання/Відповіді" },
    component: () => import("../views/Faq.vue"),
  },
  {
    path: "/feedback",
    name: "Feedback",
    meta: { title: "Відгук/Пропозиція" },
    component: () => import("../views/Feedback.vue"),
  },
  {
    path: "/contacts",
    name: "Contacts",
    meta: { title: "Контакти" },
    component: () => import("../views/Contacts.vue"),
  },
  {
    path: "/category",
    name: "Category",
    meta: { title: "Послуги" },
    component: () => import("../views/Category.vue"),
  },
  {
    path: "/service",
    name: "Service",
    meta: { title: "Послуга" },
    component: () => import("../views/Service.vue"),
  },
  {
    path: "/search",
    name: "Search",
    meta: { title: "Послуги" },
    component: () => import("../views/Search.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "Missing",
    meta: { title: "404" },
    component: () => import("../views/Missing.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title + " | ЦНАП Бердичів" : "ЦНАП | Бердичів";
  next();
});

export default router;
