export default {
  main: [
    {
      title: "Громадянство та міграція",
      discription:
        "Тут ви можете дізнатися все про паспортні послуги, реєстрацію актів цивільного стану або місця проживання.",
    },
    {
      title: "Земля та екологія",
      discription:
        "Тут ви можете дізнатися все про послуги, пов'язані з використанням землі, користуванням надрами,  водокористуванням або з екологічною безпекою.",
    },
    {
      title: "Будівництво та нерухомість",
      discription:
        "Тут ви можете дізнатися все про послуги, пов'язані з нерухомим майном та архітектурно-будівельною діяльністю.",
    },
    {
      title: "Діяльність бізнесу та громадських формувань",
      discription: "Тут ви можете дізнатися все про послуги, пов'язані з реєстрацією бізнесу та громадських формувань.",
    },
    {
      title: "Соціальний захист",
      discription:
        "Тут ви можете дізнатися всю інформацію про призначення соціальної допомоги соціально незахищеним  категоріям громадян, пенсійні послуги, опіку та піклування.",
    },
    {
      title: "Безпека життєдіяльності",
      discription:
        "Тут ви можете дізнатися все про послуги з безпеки життєдіяльності людини, а саме пов'язані з санітарним-епідеміологічним благополуччям, техногенною та пожежною безпекою або з об'єктами підвищеної небезпеки.",
    },
    {
      title: "Паливно-енергетичний комплекс та водопостачання",
      discription:
        "Тут ви можете дізнатися все про послуги, пов'язані з водопостачанням та водовідведенням, паливом, нафтою та газом або енергетикою та енергозбереженням.",
    },
  ],
};
